import React from 'react'

import logo from '../assets/images/logo.png';
import appstore from '../assets/images/appstore.png';
import android from '../assets/images/googleplay.png';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

const Header = (props) => {
    const intl = props.intl

    return <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1><FormattedMessage id="title" /></h1>
        <p><FormattedMessage id="desc" /></p>
        <div className="downloads">
            <a href="https://apps.apple.com/app/id1602939186" target="_blank">
                <img src={appstore} alt="" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.pxmage.zeniresume" target="_blank">
                <img src={android} alt="" />
            </a>
        </div>
        {intl.locale === 'zh' &&         
            <div className="china-download">
                国内安卓版可以从<a href="https://www.yuque.com/pocv40/in74g9/ayeesz" target="_blank">这里</a>下载
            </div>
        }
    </header>
}

export default Header
